.card-prompt {
  @apply mx-[15px] md:mx-0 md:w-[400px] h-[160px];
}
.card-prompt-header-wrapper {
  @apply flex mt-[5px] w-full px-[5px];

  .general {
    @apply h-[80px];
  }
  .new {
    @apply h-60px justify-center;
  }
  .mobile {
    @apply h-[45px] uppercase px-[10px] text-xs font-semibold;
  }
  .card-prompt-header {
    @apply flex items-center flex-grow w-full rounded-t-[15px] overflow-hidden text-ellipsis;
  }
  .card-prompt-header-content {
    @apply flex items-center flex-grow  w-full overflow-hidden text-ellipsis border-b dark:border-gray-1350;
  }
}


.card-new-prompt {
  @apply flex flex-col items-center relative h-full;
}

.card-new-prompt-content {
  @apply absolute top-[30px] flex flex-col items-center;
}

.card-prompt-content {
  @apply flex items-start px-[30px] h-[120px] flex-col w-[300px] md:w-[440px] relative;
}

.prompt-statut {
  @apply uppercase font-medium;

  .actif {
    @apply text-blue-1350;
  }

  .inactif {
    @apply text-red-1250;
  }
}