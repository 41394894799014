.btn {
  @apply h-[50px] font-light uppercase py-[10px] text-white dark:text-black px-[20px] text-[18px] rounded-[5px];
}
.btn-without-bg {
  @apply h-[50px] font-medium text-[13px] uppercase py-[10px] text-blue-1350 px-[15px] border border-blue-1350 rounded-[5px];
  @apply hover:bg-blue-1050 hover:dark:bg-gray-1450 hover:cursor-pointer;
}

.btn.default {
  @apply bg-blue-1350 cursor-pointer;
  @apply hover:bg-blue-1325 hover:text-white hover:dark:text-black;
}

.btn.inactive {
  @apply rounded-[15px] text-red-1300 border border-red-1300 cursor-pointer bg-white;
  @apply dark:bg-black;
  @apply hover:bg-red-1150 hover:dark:bg-red-1750;
}

.btn.cancel {
  @apply text-white md:inline-block;
  @apply bg-red-1250 dark:text-black cursor-pointer;
  @apply hover:bg-red-1200 hover:text-white hover:dark:text-black;
}

.add-icone {
  @apply hover:text-blue-1350 relative h-full w-full z-10;
  @apply dark:text-gray-1250;
}

.add-icone-fond {
  @apply absolute inset-0 bg-white dark:bg-gray-1600;
}

.btn.prompt-default-button {
  @apply md:rounded-[15px] rounded-[0] text-[13px] normal-case mx-auto flex items-center justify-center;
}

.btn.prompt-default-button.prompt-start-again {
  @apply h-[30px] bg-yellow-1700 rounded-[15px] mt-[10px];
}