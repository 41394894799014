@import 'toast.css';
@import 'form.css';
@import 'modal.css';
@import '../../../lauria/assets/styles/applicationCard.css';
@import 'layout.css';
@import '../../../lauria/assets/styles/promptForm.css';
@import '../../../lauria/assets/styles/promptCard.css';
@import '../../../lauria/assets/styles/prompts.css';
@import '../../../lauria/assets/styles/userCard.css';
@import '../../../lauria/assets/styles/applications.css';
@import '../../../lauria/assets/styles/tags.css';
@import 'common.css';
@import '../../../lauria/assets/styles/dashboard.css';
@import '../../../lauria/assets/styles/docCard.css';
@import '../../../lauria/assets/styles/promptDistribution.css';
@import '../../../lauria/assets/styles/temperatureSlider.css';
@import 'tooltip.css';
@import 'conversation.css';
@import '../../../lauria/assets/styles/kpi.css';
@import '../../../lauria/assets/styles/periodSelector.css';
@import '../../../lauria/assets/styles/profiles.css';
@import '../../../iastart/assets/styles/accueil.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @keyframes dots {
    0% {
      content: ''
    }
    33% {
      content: '.'
    }
    66% {
      content: '..'
    }
    100% {
      content: '...'
    }
  }
  html, body {
    @apply font-primary text-primary dark:text-primary-dark font-light h-screen m-0 text-[13px];
  }
  #root {
    @apply h-full;
    white-space: pre-line;
  }

  .dots::after {
    content: '';
    animation: dots 1s steps(3, end) infinite;
  }
}

