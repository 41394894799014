
.form {
  @apply flex flex-col ml-[30px] mr-[30px] md:mr-[60px];

  .tags {
    @apply md:bg-blue-1150 md:p-[15px] mt-[20px];
    @apply md:dark:bg-gray-1425;

    h4 {
      @apply md:block hidden text-primary;
      @apply dark:text-gray-1225;
    }
  }
}

.form-input {
  @apply h-[45px] rounded-[5px] outline outline-1 outline-gray-1100 dark:outline-gray-1350 pl-[15px] bg-white dark:bg-gray-1600;
  @apply focus:outline-blue-1400 focus:dark:outline-blue-1350;
}

.form-input.error {
  @apply outline-red-1300;
}

.form-error {
  @apply text-red-1300 text-[12px];
}

.form-textarea {
  @apply py-2 px-4 rounded-md outline-none dark:bg-gray-1600 border dark:border-gray-1350;
  @apply focus:border-purple-1150 focus:dark:border-purple-1200;

  &.error {
    @apply border-red-1300;
  }
}

.dark input:-webkit-autofill {
  -webkit-text-fill-color: #8C8C8C;
  -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
  box-shadow: 0 0 0px 1000px #000000 inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

form select option:first-child {
  @apply text-gray-1225;
}

.option-link {
  @apply self-end flex flex-row ml-auto h-full items-center font-semibold text-gray-1325;
  @apply hover:underline hover:cursor-pointer;
}
