@tailwind utilities;
.button-etat {
  @apply uppercase font-semibold border rounded-[15px] px-[15px] h-[45px] bg-white dark:bg-black flex items-center;
}

.button-etat.inactif {
  @apply text-red-1300 border-red-1300;
}

.button-etat.actif {
  @apply text-purple-1150 dark:text-purple-1200 border-purple-1150 dark:border-purple-1200;
}

.prompt-card {
  @apply bg-white dark:bg-black rounded-[15px] ;
}

.prompt-card-title {
  @apply font-semibold text-[20px] mb-[20px];
}

@layer utilities {
  .max-h-prompt-card {
    max-height: calc(100vh - 600px);
  }
}

.prompt-card-prompt {
  @apply max-h-[calc(100vh-500px)] min-h-[60px] overflow-y-auto p-[10px];
}

.prompt-header {
  @apply flex flex-row md:h-full items-center w-full;

  .app-title {
    @apply font-semibold text-2xl mt-2 md:text-3xl uppercase mb-2;
  }

  .tags {
    @apply hidden md:flex flex-row text-sm ml-[10px];
  }
}
.prompt-sub-header {
  @apply hidden md:flex items-center md:min-h-[70px] bg-gray-1325 dark:bg-gray-1425;

  .prompt-status {
    @apply uppercase font-medium text-[11px];
  }

  h3 {
    @apply font-medium text-[16px];
  }
}
.prompt-sub-header-mobile {
  @apply flex md:hidden items-center h-[120px] bg-white dark:bg-gray-1600 flex-col relative;
  .prompt-status {
    @apply uppercase font-medium text-[11px];
  }
  .menu-wrapper {
    @apply w-full px-[25px] h-[60px] bg-gray-1325 dark:bg-gray-1425 text-white dark:text-blue-1350;
  }
  .dropdown-menu {
    @apply z-50 absolute top-[119px] w-[calc(100%-30px)] bg-white dark:bg-gray-1600 text-primary dark:text-gray-1325 rounded-b-[15px] shadow-message-input px-30px dark:shadow-dark-bottom-menu;
  }
}

.prompt-navigation {
  @apply bg-gray-1325 dark:bg-gray-1425 min-h-[80px] md:px-24 px-6 z-0 text-white dark:text-primary-dark;

  h3 {
    @apply font-medium text-[16px] uppercase;
  }

}
.navLink {
  @apply mt-[5px] md:text-white dark:text-primary-dark flex items-center font-light underline hover:text-primary;

  .icon {
    @apply mr-15px;
  }
}
.prompt-content {
  @apply h-full overflow-y-auto flex flex-grow md:border-t border-gray-1200 dark:border-gray-1350 w-full;
  @apply bg-full-vague dark:bg-full-vague-dark bg-no-repeat bg-left-top;

  .label {
    @apply md:w-1/5 w-2/5;
  }

  .field {
    @apply md:w-8/12 w-full;
  }

  .button-remove {
    @apply cursor-pointer;
  }
  .content-prompt {
    @apply flex-1 flex flex-col md:flex-row md:mb-4 lg:mb-0 w-full;
    .state {
      @apply uppercase font-medium hover:cursor-pointer;

      &.actif {
        @apply text-blue-1350;
      }
      &.inactif {
        @apply text-red-1300;
      }
    }
    .manage-link {
      @apply ml-[20px] hover:text-blue-1400 underline hover:cursor-pointer;
    }
  }
}

.prompt-page {
  @apply h-full dark:bg-black flex flex-col;
}
.prompt-doc-container {
  @apply flex flex-col md:items-center md:justify-between bg-gray-1175 dark:bg-black md:h-full;
}
.add-prompt {
    @apply ml-[15px] w-[260px] h-[160px] items-center md:flex hidden hover:dark:text-blue-1350 hover:cursor-pointer hover:text-blue-1350;
}
.tab-link-prompt {
  @apply cursor-pointer h-full w-[340px] flex flex-col items-center flex-grow;
  .tab {
    @apply flex-grow flex flex-row items-center w-full text-gray-1125 dark:text-gray-1325 my-[8px];
    @apply hover:text-white hover:dark:text-blue-1350;
  }
}
.tab-link-prompt.tab-prompt-active {
  .trait {
    @apply h-[2px] bg-white dark:bg-blue-1350 w-[calc(100%-40px)] mt-10px my-10px;
  }
  .tab {
    @apply text-white dark:text-blue-1350;
  }
}
.tabs-prompt {
  @apply flex h-full items-center;
}
.prompt-status {
  &.actif {
    @apply text-blue-1325;
  }

  &.inactif {
    @apply text-red-1250;
  }
}
.content-prompt-fields {
  @apply md:px-60px px-30px flex flex-col pt-30px h-full w-full md:bg-gray-1175 bg-white-150 dark:bg-gray-1600;
}
.upload-doc-card {
  @apply flex flex-row items-center mb-30px md:bg-none bg-white dark:bg-gray-1600 md:p-0 p-[20px];
  @apply rounded-15 md:border-none border border-gray-1100 dark:border-gray-1350
}
.prompt-documents {
  @apply w-full bg-gray-1175 flex flex-col mt-[15px] md:px-[20px] py-[10px] overflow-y-auto dark:bg-gray-1600 dark:border dark:border-gray-1350;;
}
.modal-prompt-filter {
  @apply w-full h-auto;
  @apply dark:text-primary-dark;
  .card-filter {
    @apply  mx-auto px-[10px] flex flex-row items-center w-[280px] h-[70px] border border-gray-1050 dark:border-gray-1700 dark:bg-gray-1600 rounded-15;
  }
}
.modal-activation {
  @apply md:w-[470px] w-full h-auto;
  @apply dark:text-primary-dark;
}