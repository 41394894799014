.kpi-subheader {
  @apply max-h-[60px] min-h-[60px] bg-white-150 dark:bg-black border-b border-gray-1100 dark:border-gray-1350;
  @apply hidden md:flex flex-row items-center text-gray-1325 text-[16px];
}
.kpi-title {
  @apply font-medium pl-[60px] w-[500px];
}
.kpi-periode {
}
.kpi-wrapper {
  @apply md:flex hidden flex-row w-full h-full;
}
.kpi-sidebar {
  @apply min-w-[470px] max-w-[470px] bg-white dark:bg-gray-1600 h-[calc(100vh-201px)] flex flex-col pt-[20px] overflow-y-auto;
}
.kpi-content {
  @apply w-[calc(100%-470px)]  h-[calc(100vh-201px)] bg-full-vague dark:bg-full-vague-dark bg-no-repeat bg-left-top p-30px overflow-y-auto;
}
.kpi-card {
  @apply p-[10px] mx-[45px] h-[140px] mb-[20px] hover:cursor-pointer rounded-[5px];
}
.kpi-card.active {
  @apply dark:bg-gray-1450 bg-blue-1050 border border-blue-1350;
  .kpi-card-header {
    @apply bg-white text-blue-1350 dark:bg-gray-1600;
  }
  .col1 {
    @apply border-blue-1350;
  }
}
.kpi-card-header-wrapper {
  @apply flex flex-row items-center w-full px-[10px];

  .general {
    @apply h-[50px];
  }
  .kpi-header-icon {
    @apply z-40 rounded-[5px] bg-white dark:bg-gray-1600 h-[50px] w-[50px] border border-gray-1050 dark:border-gray-1350 items-center flex justify-center;
  }
  .kpi-header-title {
    @apply w-full ml-[-5px] z-0 text-[16px] font-medium rounded-[5px] bg-white dark:bg-gray-1600 h-[40px] border border-gray-1050 dark:border-gray-1350 items-center flex px-[15px];
  }
}
.kpi-card-content {
  @apply flex flex-row items-center py-[5px] h-[75px];
  .col {
    @apply flex flex-col w-1/2 h-full items-center;
  }
  .col1 {
    @apply border-r-[0.5px] border-gray-1050 dark:border-gray-1350;
  }
}
.kpi-content-title {
  @apply text-[16px] font-medium text-gray-1325 uppercase;
}
.kpi-content-trait {
  @apply h-[1px] w-full bg-gray-1225 mb-15px;
}
.kpi-prompt-card-list {
  @apply mt-10px flex flex-col;
}
.kpi-prompt-card {
  @apply flex items-center rounded-[5px] w-[280px] h-[60px] hover:cursor-pointer relative border border-gray-1050 dark:border-gray-1350;
  .left-border {
    @apply rounded-l-[5px] w-[15px] h-full;
  }
  .left-border.first {
    @apply bg-red-1250;
  }
  .left-border.second {
    @apply bg-yellow-1700;
  }
  .left-border.third {
    @apply bg-blue-1350;
  }
}
.kpi-prompt-card.error {
  @apply border-red-1250;
}
.kpi-prompt-card-content {
  @apply flex items-center w-[260px] h-full justify-between flex-grow;
}
.kpi-prompt-card.active {
  @apply bg-white dark:bg-gray-1600;
}
.kpi-prompt-card.active.first, .kpi-prompt-card-content.active.first {
  @apply bg-red-1050 dark:bg-gray-1600;
}
.kpi-prompt-card.active.second, .kpi-prompt-card-content.active.second {
  @apply bg-yellow-1050 dark:bg-gray-1600;
}
.kpi-prompt-card.active.third, .kpi-prompt-card-content.active.third {
  @apply bg-blue-1050 dark:bg-gray-1600;
}
.kpi-prompt-card.inactive, .kpi-prompt-card-content.inactive {
  @apply bg-white-150 dark:bg-black text-gray-1325;
}
.kpi-prompt-card.deleted, .kpi-prompt-card-content.deleted {
  @apply bg-gray-1100 dark:bg-gray-1350 line-through text-gray-1325;
}
.kpi-prompt-icon {
  @apply h-[30px] min-w-[45px];
}
.kpi-prompt-card-list-wrapper {
  @apply flex flex-row w-full flex-wrap pb-[15px] gap-y-[10px];
}
.kpi-prompt-card-error {
  @apply text-red-1250 pl-[5px];
}
.kpi-custom-tooltip {
  @apply bg-gray-1175 dark:bg-black p-2 border border-gray-1050 dark:border-gray-1350;
}