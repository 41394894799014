.button-consigne {
  @apply text-primary w-[200px] h-[45px] rounded-[15px] bg-yellow-1050 dark:bg-yellow-1700 flex items-center justify-start pl-[15px];
  @apply hover:outline hover:outline-1 hover:outline-yellow-1500 dark:text-gray-1600 dark:hover:outline-yellow-1500;
}

.button-contexte {
  @apply w-full md:w-[200px] md:ml-30px h-[45px] rounded-[15px] bg-green-1050 dark:bg-green-1700 flex items-center justify-start pl-[15px];
  @apply hover:outline hover:outline-1 hover:outline-green-1400 dark:hover:outline-blue-1450 dark:text-gray-1250;
}

.button-wrapper-right {
  @apply md:py-[10px] mt-[10px] items-center flex flex-col md:flex-row md:justify-end md:bg-white;
  @apply dark:bg-gray-1600 md:space-x-[60px];
}

.custom-file-upload {
  @apply flex flex-row items-center mt-10px cursor-pointer;
}

.delete-doc {
  @apply cursor-pointer flex flex-row items-center justify-end text-gray-1325 ml-30px;
}

.btn-mode {
  @apply w-[100px] h-[35px] border border-gray-1200 dark:border-gray-1350 flex items-center justify-center hover:cursor-pointer;
}

.btn-mode.selected {
  @apply bg-blue-1050 dark:bg-gray-1450 border-blue-1350 text-blue-1350;
}

.prompt-common-field-section {
  @apply flex md:flex-row flex-col justify-items-center rounded-[5px] border border-gray-1100 bg-white-150 dark:bg-gray-1425 dark:border-gray-1350 p-[20px] pt-[10px] mb-[20px];

  .trait-vertical {
    @apply w-[1px] h-full bg-gray-1200 dark:bg-gray-1350 mx-[20px];
  }
}

.prompt-form-fields-content {
  @apply flex-grow md:flex flex-col md:w-[55%] w-full md:px-60px px-30px pt-[20px] md:bg-white bg-white-150 dark:bg-gray-1600 h-[calc(100vh-151px)] overflow-auto;
}

.prompt-form-wrapper {
  @apply flex-1 flex md:flex-row flex-col md:mb-0 w-full md:overflow-y-auto h-full;
}

.prompt-field-label {
  @apply h-8 md:h-[45px] flex items-center;
}

.prompt-btn-submit {
  @apply w-full md:w-[230px] md:rounded-[5px] rounded-[0] md:h-[50px] h-[60px] md:block;
}

.btn-prompt-test {
  @apply w-[250px] uppercase h-[40px] rounded-[5px] bg-gray-1325 text-white dark:text-black cursor-pointer flex items-center justify-center;
}

.prompt-test-wrapper {
  @apply flex flex-col w-full;
}

.prompt-test-fields-wrapper {
  @apply rounded-[5px] py-[10px] px-[20px] flex flex-col items-center w-full bg-white border border-gray-1100 mt-[10px];
  @apply dark:bg-gray-1600 dark:border-gray-1350;
}

.btn-launch-test {
  @apply cursor-pointer w-full h-[40px] bg-blue-1350 text-white dark:text-black rounded-[5px] mt-[10px] uppercase flex items-center justify-center;
}

.test-var {
  @apply w-[180px] h-[40px] bg-gray-1175 dark:bg-gray-1375 rounded-[5px] flex items-center px-[10px];
}

.prompt-test-result {
  @apply dark:border-gray-1350 w-full rounded-[5px] py-[10px] px-[20px] flex flex-col border border-gray-1100 mt-[40px] h-[450px] overflow-y-auto;
}

.prompt-form-side {
  @apply flex flex-col items-center md:w-[45%] md:block md:h-[calc(100vh-151px)] md:overflow-y-auto px-[20px] bg-gray-1175 dark:bg-black;
}

.prompt-test-model-wrapper {
  @apply w-full flex flex-col bg-white-150 dark:bg-black border dark:border-gray-1350 border-gray-1100 p-[10px];
}

.answer-parameter {
  @apply bg-white-150 dark:bg-black border border-gray-1100 dark:border-gray-1350 p-[10px] mb-[10px];
}

.prompt-section-title {
  @apply font-semibold h-[45px] items-center flex;
}

.prompt-documents-section {
  @apply rounded-[5px] border border-gray-1100 bg-white-150 dark:bg-gray-1425 dark:border-gray-1350 p-[20px] pt-[10px] mb-[10px];
}
.prompt-action-btn {
  @apply w-[290px] h-[60px] rounded-[5px] flex flex-row items-center justify-center border;
}
.prompt-action-btn.active {
  @apply font-semibold bg-blue-1050 dark:bg-gray-1450 border-blue-1350 text-blue-1350;
}
.prompt-action-btn.inactive {
  @apply bg-white dark:bg-black border border-gray-1200 dark:border-gray-1350;
}
.action-infos {
  @apply w-full h-auto px-[20px] py-[10px] bg-white-150 dark:bg-gray-1600 border border-gray-1100 dark:border-gray-1350;
}
