.header-accueil {
  @apply md:h-[120px] h-[100px] w-full bg-white dark:bg-black flex flex-col items-center justify-center;

  .text {
    @apply text-center align-middle md:text-xl text-base md:px-0 px-40px w-full md:w-[600px];
  }
}

.cards-wrapper {
  @apply hidden md:flex md:flex-row flex-col gap-[40px] py-30px px-[50px] justify-center items-center dark:bg-gray-1600;
}

.cards-wrapper-mobile {
  @apply flex md:hidden flex-col gap-[20px] pt-[20px] px-40px justify-center items-center dark:bg-gray-1600;
}

.card-accueil-wrapper {
  @apply rounded-[15px] bg-white border border-gray-1050;
  @apply dark:bg-black dark:border-gray-1350;
  @apply hover:dark:bg-gray-1450 hover:bg-blue-1100 hover:cursor-pointer hover:border-blue-1350 hover:dark:border-purple-1100;
}

.card-accueil {
  @apply h-full flex flex-col items-center relative;

  .header-wrapper {
    @apply mt-10px w-full px-[10px];
  }

  .card-accueil-header-general {
    @apply h-[130px];
  }

  .card-accueil-header-mobile {
    @apply h-[80px];

    .title {
      @apply font-semibold text-xl ml-[20px];
    }
  }

  .card-accueil-header {
    @apply bg-white-150 w-full dark:bg-gray-1600 rounded-t-[15px] flex items-center justify-items-center;
  }

  .content {
    @apply flex md:flex-col items-center h-full;

    .title {
      @apply font-semibold text-3xl mt-[40px] h-[45px];
    }

    .text {
      @apply text-xl px-[80px] text-center mt-[20px];
    }

    .text-mobile {
      @apply text-base px-30px text-center;
    }

    .discover {
      @apply mt-[30px] flex flex-row items-center justify-center w-[200px] h-[60px];
      @apply dark:bg-black dark:border dark:border-purple-1100 dark:text-purple-1100;
      @apply hover:dark:text-purple-1100 hover:dark:bg-purple-1700;
    }
  }
}

.accueil-page-header {
  @apply flex flex-row items-center;

  .title {
    @apply md:block hidden text-3xl font-semibold;
  }
}