.card-app-header-wrapper {
  @apply w-full px-[10px];
}

.application-card {
  .card-app-header {
    @apply mt-[20px] w-full flex flex-row items-center overflow-hidden text-ellipsis pr-[10px];
  }
  .service-name {
    @apply line-clamp-2 font-medium;
  }
}

.new-card {
  @apply flex flex-col items-center h-full;

  .new-card-app-header-wrapper {
    @apply h-[70px] flex items-center justify-center mt-[10px] w-full px-[5px];
  }
  .new-card-app-header {
    @apply h-[60px] flex-grow w-full flex items-center justify-center rounded-t-[15px] bg-white-150 dark:bg-black;
  }
}

.new-card-content {
  @apply flex-grow h-full w-full flex justify-center items-center;
}

.card-app-content {
  @apply flex ml-[20px] h-full;

  .app-name {
    @apply mb-[5px] w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-medium;
  }
}

.add-app {
  @apply w-[260px] h-[140px] items-center md:flex hidden hover:dark:text-blue-1350 hover:cursor-pointer hover:text-blue-1350;
}

.application-card {
  @apply flex flex-col w-[260px] h-[140px];
}

.button-add-app {
  @apply md:hidden mx-auto flex items-center justify-center bg-white dark:bg-black text-purple-1150 dark:text-purple-1200 rounded-[30px] border border-purple-1150 dark:border-purple-1200 p-[15px];
}

.mobile-card {
  @apply min-w-[150px] max-w-[150px] h-[100px] mr-[15px] overflow-hidden;

  .card-app-content {
    @apply flex flex-col h-full;
  }
}