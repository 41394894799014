.card {
  @apply rounded-[15px] bg-white border border-gray-1050;
  @apply dark:bg-gray-1600 dark:border-gray-1350;
}
.with-hover {
  @apply hover:dark:bg-gray-1450 hover:bg-blue-1050 hover:cursor-pointer hover:border hover:border-blue-1350;
}

.card-list {
  @apply flex md:flex-wrap md:flex-row flex-col justify-start md:gap-4 md:gap-y-[30px] gap-y-[20px] pb-[30px] h-fit;
}
.card-list.tag-mobile {
  @apply min-h-[calc(100vh-250px)] md:min-h-0 md:overflow-auto overflow-y-scroll bg-white-150 md:pt-0 pt-30px;
  @apply dark:bg-black;
}

.card-list-mobile {
  @apply flex md:hidden flex-wrap justify-start gap-4 mb-[30px];
}