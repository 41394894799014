.dark .react-responsive-modal-overlay {
  background: rgba(28, 28, 28, 0.7) !important;
}

.react-responsive-modal-overlay {
  background: rgba(77, 81, 84, 0.7) !important;
}

.react-responsive-modal-container {
  @apply flex items-center justify-center;
}

.react-responsive-modal-modal {
  @apply p-0 !important;
  @apply dark:bg-black !important;
}

.modal {
  @apply rounded-[5px] outline outline-1 outline-gray-1100 bg-white p-0;
  @apply dark:outline-gray-1350 dark:bg-black;
}

.modal-header {
  @apply ml-30px mt-[20px] w-[220px] md:w-10/12;
}

.modal-title {
  @apply text-[18px] font-medium;
}

.modal-body {
  @apply my-30px;
}

.react-responsive-modal-closeButton {
  @apply md:hidden dark:text-white focus:outline-none;
}