.loader {
  @apply inline-block w-16 h-16 rounded-1/2 border-4 border-blue-1350 animate-spin;
  border-left: theme('borderWidth.4') solid;
  border-left-color: theme('colors.gray.1225');
}
.loader .loader-sm {
  @apply h-6 w-6;
}

.dark .loader {
  border-left-color: theme('colors.gray.1250');
}

.button-loader {
  @apply inline-block w-6 h-6 rounded-1/2 border-4 border-gray-1200 animate-spin;
  border-left: theme('borderWidth.4') solid;
  border-left-color: theme('colors.blue.1325');
}