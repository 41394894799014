
.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply text-blue-1400 dark:text-purple-1100 dark:bg-purple-1700 bg-blue-1150 font-primary;
}
.Toastify__toast {
  @apply rounded-[30px] flex items-center;
}
.Toastify__toast-body {
  @apply pl-[15px];
}
.Toastify__toast-theme--colored.Toastify__toast--success .Toastify__close-button {
  @apply text-blue-1400 dark:text-purple-1100 self-center mr-[20px];
}