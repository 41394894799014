.btn-option {
  @apply flex flex-col items-center cursor-pointer ml-4 text-gray-1125 hover:text-white;
}

.app-list-wrapper {
  @apply flex-grow mt-[20px] md:ml-30px ml-[15px] md:w-full h-[calc(100vh-180px)] md:h-[calc(100vh-90px)] overflow-y-auto;

  .header {
    @apply hidden md:block text-gray-1325 font-medium text-[16px] mr-30px;

    .trait {
      @apply bg-gray-1100 dark:bg-gray-1350 h-[0.5px] w-full mt-[20px] mb-[20px];
    }
  }
}
.modal-application {
  @apply md:w-[750px] w-full h-auto;
  @apply dark:text-primary-dark;
}