.dashboard-wrapper {
  @apply flex md:flex-row flex-col w-full;

  .dashboard-side {
    @apply md:flex hidden flex-col bg-dashboard-side dark:bg-dashboard-side-dark bg-cover z-0;

    .filter-card {
      @apply border border-gray-1100 bg-gray-1175 px-[20px] pb-[20px] pt-[5px] rounded-[5px];
      @apply dark:border-gray-1350 dark:bg-black;
    }
  }
}