.documents-wrapper {
  @apply w-full flex flex-row mt-[10px];
}

.prompt-document-list-content {
  @apply grid grid-cols-3 gap-3;
}

.card-doc {
  @apply bg-white dark:bg-gray-1425 flex flex-row w-[140px] h-[50px] items-center justify-center rounded-[5px];
}

.upload-doc-label {
  @apply text-blue-1350 flex flex-row cursor-pointer h-[50px] items-center leading-5;
}

.nbDoc {
  @apply w-[55px] h-[25px] rounded-[30px] bg-blue-1350 text-white flex flex-row items-center justify-center;
}