.period-selector {
  @apply flex flex-row items-center;
}
.dropdown-period {
  @apply w-[250px] ml-10px mr-[150px] text-[13px] hover:cursor-pointer;
}
.controls {
  @apply flex flex-row items-center gap-x-[15px];
}
.button-selector-icon {
  @apply absolute inset-0 flex items-center justify-center transition-opacity duration-200;
}
.button-selector {
  @apply relative flex items-center justify-center w-[30px] h-[30px];
}